<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Datos Básicos</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
                v-model="name"
                :counter="nameMax"
                :maxlength="nameMax"
                :rules="nameRules"
                label="Nombre del Archivo"
                required
            ></v-text-field>

            <v-file-input
                show-size
                truncate-length="15"
                label="Archivo"
                accept=".pdf,.jpg,.png,.jpeg,.webp,.svg,.tiff"
                v-model="file"
                required
            ></v-file-input>

            <v-text-field
                style="display: none"
                v-model="size"
                type="number"
                required
            ></v-text-field>

            <v-text-field
                style="display: none"
                v-model="extension"
                required
            ></v-text-field>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
import { titleCase } from "@/modules/tools";

const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            name: '',
            nameMin: 0,
            nameMax: 1,
            nameRules: [
                v => !!v || 'El nombre es requerido',
                v => (v && v.length >= this.nameMin) || `El nombre debe tener al menos ${this.nameMin} letras`,
                v => (v && v.length <= this.nameMax) || `El nombre no debe superar las ${this.nameMax} letras`,
            ],
            file: '',
            size: 0,
            extension: ''
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        file: function() {
            if (this.file != '') {
                this.name = this.file.name;
                this.size = this.file.size;
                this.extension = this.name.split('.').at(-1).toUpperCase();
            }
        }
    },
    updated() {
        this.$emit('valid', {
            step: 1,
            valid: this.valid,
            data: {
                name: this.name,
                size: this.size,
                extension: this.extension,
                file: this.file,
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        schemaData.forEach(schema => {
            if (schema.store === "filem") {
                this.nameMin = schema.data.name.min;
                this.nameMax = schema.data.name.max;
            }
        });
        this.valid = false;
        this.mountedFinish = true;
    },
}
</script>